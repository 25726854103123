import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Stuff from './Stuff';

export default function App() {
  return (
    <div>
      <Stuff/>
    </div>
  );
}